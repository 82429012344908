/**
 * Ye olden scope containment pattern for IE
 */
 (function() {
  /** Stores current state for nav bars */
  var state = {
    openMenu: null,
    openLink: null,
    ctx: null,
    mobileNav: null,
    mobileNavToggle: null,
    mobileNavIsOpen: false
  };

  /** This is the id to be used to specify the modal root. */
  var modalRootId = "__modal_root__";
  var closer = document.createElement("div");

  /**
   * This guarantees the modal root to exist at the body root level
   */
  function ensureModalRoot() {
    var modalRoot = null;
    var root = document.getElementById(modalRootId);

    if (root instanceof HTMLDivElement) {
      modalRoot = root;
    } else {
      var body = document.getElementsByTagName("body")[0];
      modalRoot = document.createElement("div");

      if (body && modalRoot) {
        body.appendChild(modalRoot);
      }
    }

    if (modalRoot) {
      modalRoot.style.position = "fixed";
      modalRoot.style.top = "0px";
      modalRoot.style.left = "0px";
      modalRoot.style.width = "100%";
      modalRoot.style.zIndex = "999999";
      modalRoot.style.display = "flex";
      modalRoot.style.flexDirection = "column";
      // modalRoot.style.justifyContent = "center";
      modalRoot.style.alignItems = "center";
      modalRoot.id = modalRootId;
    }

    return modalRoot;
  }

  /**
   * Generates child position information relative to parent.
   */
  function getRelativeRect(parent, child) {
    var parentPos = parent.getBoundingClientRect();
    var childPos = child.getBoundingClientRect();
    relativePos = {};

    relativePos.top = childPos.top - parentPos.top,
    relativePos.right = childPos.right - parentPos.right,
    relativePos.bottom = childPos.bottom - parentPos.bottom,
    relativePos.left = childPos.left - parentPos.left;
    relativePos.width = childPos.width;
    relativePos.height = childPos.height;

    return relativePos;
  }

  /**
   * Removes the menu from the screen
   */
  function closeMenu(ctx, keepMarkers) {
    if (state.openMenu) {
      closer.remove();
      state.openMenu.remove();
      state.openMenu = null;
    }
  }

  /**
   * Removes the menu from the screen
   */
  function handleClose() {
    closeMenu(state.ctx);
  }

  /**
   * Link click handler generator. Moves the marker to the link,
   */
  function handleLinkClick(link, menu, ctx) {
    return function() {
      if (state.openMenu === menu) {
        closeMenu(ctx);
        document.getElementById("NavBar__Button-GetStarted").classList.remove("NavBar__Button--Open");
    
        return;
      }

      // Update the DOM to the correct state
      if (state.openMenu !== menu) closeMenu(ctx);
      var marker = ctx.marker;
      var navbar = ctx.navbar;
      var modal = ensureModalRoot();
      modal.insertBefore(closer, navbar);
      modal.appendChild(menu);

      // Apply all the styling needed to get to the correct state

      var navPos = navbar.getBoundingClientRect();
      var navBottom = navPos.top + navPos.height;

      if (marker) {
        var markerPos = getRelativeRect(link.parentElement, link);
        if (link.dataset.nav == "search" || link.dataset.nav == "getStarted") {
          marker.style.opacity = "0";
        } else {
          marker.style.opacity = "1";
          marker.style.left = markerPos.left + "px";
          marker.style.width = markerPos.width + "px";
        }
      }

    
      if (link.dataset.nav == "getStarted") {
        link.classList.add("NavBar__Button--Open");
      } else {
        document.getElementById("NavBar__Button-GetStarted").classList.remove("NavBar__Button--Open");
      }

      // Style the menu for opening
      menu.style.display = "block";
      setTimeout(() => {
        menu.style.opacity = "1";
        menu.style.visibility = "visible";
      },50); // For animating in
      closer.style.height = window.innerHeight + "px";

      var mobileBackButton = document.querySelectorAll('.MobileDropdownNav__BackButton')[0];
      mobileBackButton.addEventListener("mousedown", function() { handleClose() });


      // Store our current open state
      state.openMenu = menu;
      state.openLink = link;
      state.ctx = ctx;
    };
  }

  /**
   * Readjust markers for resized window
   */
  function handleResize() {
    if (!state.openMenu) return;
    var ctx = state.ctx;
    var link = state.openLink;
    var menu = state.openMenu;
    var marker = ctx.marker;

    if (marker) {
      var markerPos = getRelativeRect(link.parentElement, link);
      marker.style.opacity = "1";
      marker.style.left = markerPos.left + "px";
      marker.style.width = markerPos.width + "px";
    }

    // Style our arrow to line up with the open element
    var linkPos = getRelativeRect(menu, link);
  }

  /**
   * Initialize a link and the region it is intended to monitor.
   */
  function initLink(link, ctx) {
    var navId = link.dataset.nav;
    var menu, found;

    for (var i = 0; i < ctx.menus.length; ++i) {
      menu = ctx.menus[i];

      if (menu.dataset.menu === navId) {
        found = true;
        break;
      }
    }
    
    if (menu === void 0 || !found) return;
    link.addEventListener("click", handleLinkClick(link, menu, ctx));

  }

  /**
   * Places the marker for the menu in its initial state
   */
  function initMarker(marker) {
    if (marker === void 0) return;
    marker.style.opacity = "0";
  }

  /**
   * Initializes a menu to it's default state
   */
  function initMenu(menu) {
    if (menu === void 0) return;
    menu.style.position = "fixed";
    menu.style.opacity = "0";
    menu.style.display = "none";
    menu.remove();
  }

  /**
   * Initializes a navbar context that is available
   */
  function initNav(navbar) {
    if (!(navbar instanceof HTMLElement)) return;
    var marker = navbar.querySelectorAll('[data-nav-marker]')[0];
    var links = navbar.querySelectorAll('[data-nav]');
    var menus = document.querySelectorAll('[data-menu]');

    initMarker(marker);

    var ctx = {
      marker: marker,
      navbar: navbar,
      menus: menus,
    };

    for (var i = 0; i < menus.length; ++i) {
      var menu = menus[i];
      initMenu(menu);
    }

    for (var i = 0; i < links.length; ++i) {
      var link = links[i];
      initLink(link, ctx);
    }

    // Make sure the nav bar is attached to the modal and replace it's location
    // in the dom with an exact size replica
    var spacer = document.createElement("div");
    var navPos = navbar.getBoundingClientRect();
    navbar.parentElement.insertBefore(spacer, navbar);
    spacer.style.width = navPos.width + "px";
    spacer.style.height = navPos.height + "px";
    var modal = ensureModalRoot();
    modal.appendChild(navbar);
  }
  
  /**
   * Toggle the mobile nav when the navicon is tapped
   */
  function toggleMobileNav() {
    if (state.mobileNavIsOpen) {
      hideMobileNav();
    } else {
      showMobileNav();
    }
  }
  
  /**
   * Show the mobile nav and ensure no dropdowns are active
   */
  function showMobileNav() {
    state.mobileNavIsOpen = true;
    state.mobileNavToggle.classList.add('NavBar__MobileNavicon--Close');
    state.mobileNav.classList.add("NavBar__MobileDropdown--Visible");
  }
  
  /**
   * Hide the mobile nav and any visible dropdowns
   */
  function hideMobileNav() {
    state.mobileNavIsOpen = false;
    state.mobileNavToggle.classList.remove('NavBar__MobileNavicon--Close');
    state.mobileNav.classList.remove("NavBar__MobileDropdown--Visible");
    handleClose();
  }

  /**
   * Entry point of execution. Consumes the DOM and modifies specially marked
   * elements.
   */
  function main() {
    var navbars = document.querySelectorAll('[data-navbar]');

    // Poll for nav bars to be available, which is indication enough that the
    // DOM is ready.
    if (navbars.length === 0) {
      setTimeout(function() { main(); }, 100);
      return;
    }

    for (var i = 0; i < navbars.length; ++i) {
      var navbar = navbars[i];
      initNav(navbar);
    }

    // Initialize the div that detects click outside of the menu events
    closer.style.position = "absolute";
    closer.style.top = "0px";
    closer.style.left = "0px";
    closer.style.width = "100%";
    closer.addEventListener("mousedown", function() { handleClose() });

    window.addEventListener("resize", handleResize);
    
    // Handle mobile opening / closing of the nav
    state.mobileNav = document.querySelector('[data-mobile-nav]');
    state.mobileNavToggle = document.querySelector('[data-mobile-nav-toggle]');

    if (state.mobileNavToggle) {
      state.mobileNavToggle.addEventListener("click", function() { toggleMobileNav() }); 
    }
    
  }

  main();
  
})();

let contractsDropdowns = document.querySelectorAll('#contracts-nav .dropdown-btn');

contractsDropdowns.forEach(contractsDropdown => {
  contractsDropdown.addEventListener('click', (e) => { 
        e.preventDefault();
        const id = contractsDropdown.getAttribute('data-subnav-value');
        document.querySelector(`#contracts-nav .sub-nav[data-subnav-id="${id}"]`).classList.toggle('visible');
        contractsDropdown.classList.toggle('plus');
    });
});


let activeNav = document.querySelector(".child.active .sub-nav");

if (activeNav) {
  activeNav.classList.toggle('visible');
}

var hamburger = document.querySelector('#hamburger');
var hamburgerIcon = document.querySelector('#hamburger .hamburger');

// Hamburger
if (hamburger) {
  hamburgerIcon.addEventListener('click', function () {
    hamburger.classList.add('active');
    document.querySelector('body').classList.toggle('frozen');
    document.querySelector('#navbar').classList.toggle('transparent');
    document.querySelector('.main-menu').classList.add('active');
    document.querySelector('#navbar-logo').classList.add('active');
  });
}

var closeIcon = document.querySelector('#hamburger .close');

// Close
if(closeIcon) {
  closeIcon.addEventListener('click' , function () {
    hamburger.classList.remove('active');
    document.querySelector('body').classList.toggle('frozen');
    document.querySelector('#navbar').classList.toggle('transparent');
    document.querySelector('.main-menu').classList.remove('active');
    document.querySelector('#navbar-logo').classList.remove('active');
    document.ontouchmove = function (e) { return true; };
  });
}
