(function() {
  
  window.addEventListener('load', function () {
    initFeatureWrapperPositioning();
  });
  
  var features = document.querySelectorAll('.MDRInfo__Feature');
  featureCount = features.length
  currentFeature = 0;
  
    if (features.length) {
        for (var i = 1; i < features.length; ++i) {
            // Skipping the first one so it doesn't animate in
            features[i].classList.add('MDRInfo__Feature--Entering');
        }
        features[0].classList.add('MDRInfo__Feature--Active');
    }

  function initFeatureWrapperPositioning() {
    // Moving this element to the root is necessary to get it to sit above both adjacent blocks
      var featuresElement = document.querySelector('.MDRInfo__Features');
      if (featuresElement) {
        featuresElement.classList.add('MDRInfo__Features--Rooted');
        document.querySelector('body').append(featuresElement);
        updateFeatureWrapperPosition();
        
        window.addEventListener("resize", updateFeatureWrapperPosition);
        setInterval(nextFeature, 5000);
      }
  }

  function updateFeatureWrapperPosition() {
    // Wrapper stays where the element should be showing
    var featuresWrapper = document.querySelector('.MDRInfo__FeaturesWrapper');
    var offset = getOffset(featuresWrapper);
    
    // This elementis now a root level element, and should be positioned directly over the wrapper
    var featuresElement = document.querySelector('.MDRInfo__Features');
    featuresElement.style.top = offset.top + 'px';
    featuresElement.style.left = offset.left + 'px';
  }

  function getOffset(el) {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  function moveToFeature(n) {
    var fromIndex = currentFeature;
    var toIndex = (n + features.length) % features.length;

    features[fromIndex].classList.add('MDRInfo__Feature--Leaving');
    features[fromIndex].classList.remove('MDRInfo__Feature--Active');
    
    features[toIndex].classList.add('MDRInfo__Feature--Active');
    features[toIndex].classList.remove('MDRInfo__Feature--Entering');
    setTimeout(() => {
      features[fromIndex].classList.remove('MDRInfo__Feature--Leaving');
      features[fromIndex].classList.add('MDRInfo__Feature--Entering');
    }, 600);
    
    currentFeature = toIndex;
  }

  function nextFeature(){
    moveToFeature(currentFeature+1);
  };
})();
