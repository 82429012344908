(function () {
  var newsBlock = null;
  var newsArticles = null;
  var dotNav = null;
  var currentArticle = 0;
  var newsInterval = null;

  function initNewsNav() {
    newsBlock = document.querySelector('.Intro__NewsBox');
    if (!newsBlock) return;
    
    newsArticles = newsBlock.getElementsByClassName('Intro__NewsArticle');
    if (!newsArticles.length) return;

    dotNav = document.querySelector('.Intro__NewsDotsNav');
    if (!dotNav) return;

    newsArticles[currentArticle].classList.add('Intro__NewsArticle--Active');

    for (i = 0; i < newsArticles.length; i++) {
      var newDot = document.createElement("button");
      newDot.setAttribute('type', 'button');
      newDot.dataset.index = i;
      newDot.classList.add('Intro__NewsDot');
      if (i === 0) {
        newDot.classList.add('Intro__NewsDot--Active');
      }
      newDot.addEventListener("click", handleDotNavClick);
      dotNav.appendChild(newDot);
    }
    
    if (newsBlock.getAttribute('data-auto-cycle') !== null) {
      newsInterval = setInterval(nextArticle, 5000)
    }

    var nextButton = newsBlock.querySelector('.Intro__ArrowButton');
    nextButton.addEventListener('click', handleArrowClick);
  }

  function moveToArticle(index) {
    newsBlock.querySelector('.Intro__NewsArticle--Active')
      .classList
      .remove('Intro__NewsArticle--Active');

    newsArticles[index]
      .classList
      .add('Intro__NewsArticle--Active');

    dotNav.querySelector('.Intro__NewsDot--Active')
      .classList
      .remove('Intro__NewsDot--Active');
    
    dotNav.querySelectorAll('.Intro__NewsDot')[index]
      .classList
      .add('Intro__NewsDot--Active');

    currentArticle = index;
  }

  function handleDotNavClick(e) {
    clearInterval(newsInterval); // Once the user interacts with the news nav, stop any automatic animation
    moveToArticle(e.target.dataset.index);
  }

  function handleArrowClick() {
    clearInterval(newsInterval); // Once the user interacts with the news nav, stop any automatic animation
    nextArticle();
  }

  function nextArticle() {
    var newIndex = currentArticle + 1;
    if (newIndex >= newsArticles.length) {
      newIndex = 0;
    }
    moveToArticle(newIndex);
  }

  initNewsNav();
})();
  