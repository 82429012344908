let mdrFeatureTrackers = document.querySelectorAll('.mdr-feature-trackers');
let mdrFeatureImage = document.getElementById("mdr-feature-tracker-image");

mdrFeatureTrackers.forEach(mdrFeatureTracker => {
    mdrFeatureTracker.addEventListener('mouseover', (e) => {
        e.preventDefault();

        for (var item of mdrFeatureTrackers) {
            item.classList.remove('active');
        }

        if (document.documentElement.clientWidth > 768) {
            mdrFeatureTracker.classList.add('active');
            const imageURL = mdrFeatureTracker.getAttribute('data-modal-tracker');

            mdrFeatureImage.src = imageURL;
        }
        
        
    });
});