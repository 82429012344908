(function() {
  function empty(node) {
    while (node.firstChild) node.removeChild(node.firstChild);
  }

  function toList(collection) {
    const out = [];

    for (var i = 0; i < collection.length; ++i) {
      out.push(collection[i]);
    }

    return out;
  }

  var handleClickDot = (dot, page) => () => {
    cancelAuto = true;
    dot.className = "Awards__FeaturedInDot Awards__FeaturedInDot--Selected";
    nextPage(articleContainer, articles, dots, page);
  }

  function addDots(node, count) {
    const out = [];

    for (var i = 0; i < count; ++i) {
      var dot = document.createElement("button");
      dot.className = "Awards__FeaturedInDot";
      out.push(dot);
      node.appendChild(dot);

      dot.addEventListener("click", handleClickDot(dot, i));
    }

    return out;
  }

  function nextPage(container, articles, dots, forcePage) {
    // Make the current page transition out
    const current = articles[currentPage];

    if (current) {
      Array.from(current.getElementsByTagName("h6")).forEach(t => {
        t.style.opacity = "0";
        t.style.transform = "translateX(30px)";
      });

      setTimeout(() => {
        Array.from(current.getElementsByTagName("h4")).forEach(t => {
          t.style.opacity = "0";
          t.style.transform = "translateX(30px)";
        });
      }, 100);

      setTimeout(() => {
        Array.from(current.getElementsByTagName("a")).forEach(t => {
          t.style.opacity = "0";
          t.style.transform = "translateX(30px)";
        });
      }, 200);
    }

    // Wait for transition out to complete, then add in the new article
    setTimeout(() => {
      for (var i = 0; i < articles.length; ++i) {
        articles[i].remove();
      }

      currentPage = (currentPage + 1) % articles.length;
      if (forcePage !== void 0) currentPage = forcePage;

      const article = articles[currentPage];
      container.prepend(article);

      Array.from(article.getElementsByTagName("h6")).forEach(t => {
        t.style.opacity = "1";
        t.style.transform = "translateX(0px)";
      });

      setTimeout(() => {
        Array.from(article.getElementsByTagName("h4")).forEach(t => {
          t.style.opacity = "1";
          t.style.transform = "translateX(0px)";
        });
      }, 100);

      setTimeout(() => {
        Array.from(article.getElementsByTagName("a")).forEach(t => {
          t.style.opacity = "1";
          t.style.transform = "translateX(0px)";
        });
      }, 200);

      // Reset dots active state
      for (i = 0; i < dots.length; ++i) {
        const dot = dots[i];

        if (i === currentPage) {
          dot.className = "Awards__FeaturedInDot Awards__FeaturedInDot--Active";
        }

        else {
          dot.className = "Awards__FeaturedInDot";
        }
      }
    }, animationDuration);
  }

  var animationDuration = 700;
  var pageInterval = 10000;
  var currentPage = -1;
  var cancelAuto = false;
  var articleContainer = document.getElementsByClassName("Awards__FeaturedIn")[0];
  if (!articleContainer) return;
  var dotContainer = document.getElementsByClassName("Awards__FeaturedInDotsNav")[0];
  var articles = toList(document.getElementsByClassName("Awards__FeaturedInArticle"));

  empty(dotContainer);
  const dots = addDots(dotContainer, articles.length);
  nextPage(articleContainer, articles, dots);

  setInterval(() => {
    if (cancelAuto) {
      cancelAuto = false;
      return;
    }

    nextPage(articleContainer, articles, dots);
  }, pageInterval);
}());
