(function() {
  var left = document.getElementsByClassName("CyberTales__ArrowLeft")[0];
  var right = document.getElementsByClassName("CyberTales__ArrowRight")[0];
  var articles = Array.from(document.getElementsByClassName("CyberTales__Article"));
  var currentArticle = articles[articles.length - 1];
  var articleIndex = articles.length - 1;
  if (!left || !right || articles.length === 0 || !currentArticle) return;

  var next = () => {
    currentArticle.className = "CyberTales__Article";
    articleIndex++;
    if (articleIndex >= articles.length) articleIndex = 0;
    currentArticle = articles[articleIndex];
    if (!currentArticle) return;
    currentArticle.className = "CyberTales__Article CyberTales--active";
  };

  var prev = () => {
    currentArticle.className = "CyberTales__Article";
    articleIndex--;
    if (articleIndex < 0) articleIndex = articles.length - 1;
    currentArticle = articles[articleIndex];
    if (!currentArticle) return;
    currentArticle.className = " CyberTales__Article CyberTales--active";
  };

  left.addEventListener("click", prev);
  right.addEventListener("click", next);

  next();
})();
