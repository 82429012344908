import Vue from 'vue';
import VueAgile from 'vue-agile/dist/VueAgile.common';

let numberOfSlides = document.querySelectorAll('#case-study-slider .slide').length;

if (document.querySelector('#case-study-slider') !== null) {
  let app = new Vue({
    el: '#case-study-slider',
    delimiters: ['${', '}'],
    data () {
      return {
        options: {
          autoplay: numberOfSlides == 1 ? false : true,
          autoplaySpeed: 10000,
          dots: false,
          navButtons: numberOfSlides == 1 ? false : true,
          timing: 'ease-in-out'
        }
      };
    },
    components: {
      agile: VueAgile,
    }
  });
}