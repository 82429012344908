import anime from 'animejs/lib/anime.es.js';

document.addEventListener("DOMContentLoaded", function() {
  if (document.querySelector('#hero .image svg')) {

    let svg = document.querySelector('#hero .image svg');

    var tl = anime.timeline({
      easing: 'easeInQuad',
      duration: 500
    });

    tl.add({
      targets: [
        svg.querySelector('#orange .server'),
        svg.querySelector('#red .server'),
        svg.querySelector('#purple .server'),
        svg.querySelector('#green .server')
      ],
      translateY: [-250, 0],
      opacity: [0, 1],
      delay: anime.stagger(200)
    });

    tl.add({
      targets: [
        svg.querySelector('#orange .shield'),
        svg.querySelector('#red .shield'),
        svg.querySelector('#purple .shield'),
        svg.querySelector('#green .shield')
      ],
      translateY: [-250, 0],
      opacity: [0, 1],
      easing: 'easeInBounce',
      delay: anime.stagger(200)
    });

    tl.add({
      targets: [
        svg.querySelector('#orange .server .line'),
        svg.querySelector('#red .server .line'),
        svg.querySelector('#purple .server .line'),
        svg.querySelector('#green .server .line'),
        svg.querySelector('#computer #outline path')
      ],
      opacity: [0, 1],
      strokeDashoffset: [anime.setDashoffset, 0],
      delay: anime.stagger(100)
    }, '-=800');

    tl.add({
      targets: svg.querySelector('#computer #eSentire'),
      opacity: [0, 1],
      scale: [0.95, 1.0],
    });

    tl.add({
      targets: svg.querySelector('#computer #outline image'),
      opacity: [0, 1],
    }, '-=500');
  }
});