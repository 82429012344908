if (document.querySelector('#library-filter')) {
  let filters = document.querySelectorAll('.topic, .type, .industries');
  let clearBtns = document.querySelectorAll('.btn-clear');
  const submitBtns = document.querySelectorAll('.submit');

  filters.forEach(filter => {
    filter.querySelector('.header').addEventListener('click', e => {
      e.preventDefault();

      openDrawer(filters, filter);
    });
  });

  clearBtns.forEach(btn => {
    btn.addEventListener('click', e => {
      e.preventDefault();

      btn.closest('.active').querySelectorAll('.options .option input').forEach(box => {
        box.checked = false;
      });
    });
  });
}

function openDrawer (filters, filter) {
  if (filter.classList.contains('active')) {
    filter.classList.remove('active');
  } else {
    filters.forEach(filter => {
      filter.classList.remove('active');
    });

    filter.classList.add('active');
  }
}

let chips = document.querySelectorAll('.chipOpenDrawer');

chips.forEach((chip) => {
  chip.addEventListener('click', (e) => { 
      e.preventDefault();

      const category = chip.getAttribute('data-chip-drawer');

      document.querySelector(`.${category}`).classList.add('active');
  });
});

let chipsCloseBtn = document.querySelectorAll('.chipDeleteButton');

chipsCloseBtn.forEach((chip) => {
  chip.addEventListener('click', (e) => { 
      e.preventDefault();
      
      let chipValue = encodeURIComponent(chip.getAttribute('data-chip-close')).split('%20').join('+');

      let newLocation = window.location.href.replace(chipValue, '');
      newLocation = newLocation + ' ';

      newLocation = newLocation.replace('topics%5B%5D= ', '');
      newLocation = newLocation.replace('topics%5B%5D=&', '');

      newLocation = newLocation.replace('resourceType%5B%5D= ', '');
      newLocation = newLocation.replace('resourceType%5B%5D=&', '');

      newLocation = newLocation.replace('industries%5B%5D= ', '');
      newLocation = newLocation.replace('industries%5B%5D=&', '');

      window.location.href = newLocation;
  });
});

let clearAllFilters = document.querySelectorAll('.clear-filters .btn-rounded');

clearAllFilters.forEach((chip) => {
  chip.addEventListener('click', (e) => { 
    e.preventDefault();
    window.location.href = "/resources/library";
  });
});