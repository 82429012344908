let dropdowns = document.querySelectorAll('.expand-icon');

dropdowns.forEach(dropdown => {
    dropdown.addEventListener('click', (e) => { 
        e.preventDefault();

        const id = dropdown.getAttribute('data-modal-dropdown');

        document.querySelector(`.dropdown-content[data-modal-dropdown-id="${id}"]`).classList.toggle('active');

        document.querySelector(`.expand-icon[data-modal-dropdown="${id}"] .plus-sign`).classList.toggle('disable');   
        document.querySelector(`.expand-icon[data-modal-dropdown="${id}"] .minus-sign`).classList.toggle('disable');   
        
    });
});